@import url('https://fonts.googleapis.com/css2?family=Lato&family=Signika+Negative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

.white{
    color: #FFFFFF;
    font-family: 'Signika Negative', sans-serif;
}
.black{
    color:black;
    font-family: 'Signika Negative', sans-serif;
}
a{
    text-decoration: none;
}
@media only screen and (min-width: 600px){
    .Home{
        display: flex;
        flex-direction: column;

        background-size: cover;
        background-repeat: no-repeat;
        background-image:url("./../images/ankarada1.jpeg");
        height: 100vh;
        
    }
/*Menu.js*/
nav{

    top: 0;
    opacity: 1;  
    display: flex;
    flex-direction: row;
    padding-left: 3vh;
    padding-right: 3vh;
    
}

nav>span{
    font-size: 10vh;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    z-index: 1;
    
}
nav>div{
    /* Layout Properties */
    margin-left: auto;
    display: flex;
    /* UI Properties */
    opacity: 1;
}
nav>div>span{
    /* Layout Properties */
    margin: 1rem;
    /* UI Properties */
    font-family: 'Signika Negative', sans-serif;
    font-family: 'Lato', sans-serif;
    font-size: 5vh;
    text-align: left;
    letter-spacing: 1.56px;
    opacity: 1;
    z-index: 1;
}
/*earchbar-main.js*/
.SearchBarMain{
    color:black;
    width: 60%;
    background-color: #FFFFFF;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    align-self: center;
    margin-top: 38vh;
}

.fRouter{
    position:absolute;
    bottom: 0;
    margin: 1vw;
    display: flex;
    justify-content: space-evenly;
    padding-top: 34.5vh;
    text-align: center;
    width: 98vw;
    
}
.fRouter-element{
    text-align: center;
    width:40vh;
    border-bottom: #FFFFFF solid 1px;
    font-family: 'Lato', sans-serif;
    font-size: 5vh;
    letter-spacing: 1.56px;
    transition: ease-out 0.3s background-color,ease-out 0.3s color, ease-out 0.3s border, ease-out 0.3s border-radius;
    
}
.fRouter-element:hover{
    background-color:rgb(255, 255, 255,0.5) ;
    border-radius: 1rem;
    border: none;
    color: #000000;
}
.dd{
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: 50% 50%;
    width:100vw;
    position:absolute;
    top: 10vh;
    left: 0;
    font-size: 4vh;
    border: 1px solid black;
}
.ddHome{
    background-color: rgba(0, 0, 0, 0.46);
    top: 0;
    padding-top: 10vh;
    z-index: 0;
}
.col{
    display: flex;
    flex-direction: column;
    margin-left: 20vh;
}


.FilterMenu{
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: row;
    padding-left: 3vh;
    padding-right: 3vh;
}
.filter-calender{
    margin-left:auto ;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF0E41;
    color: #FFFFFF;
    font-size: 5.5vh;
    border-radius: 41px;
    box-shadow: 0px 3px 6px #0000001A;
    width: 28vh;
    height: 10vh;
    
}
.filterMenu-label{
    font-size: 5vh;
    color: #FF0E41;
}
.filter-label{
    font-size: 5vh;
    font-family: 'Lato', sans-serif;
    font-weight:200;
}
.filter{
    position:absolute;
    background-color:#FFFFFF;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    width: 300px;
}
.filter>span{
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 5vh;
    font-family: 'Lato', sans-serif;
    font-weight:200;
    
}



.eventContainer{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    margin-left: 1%;
    width: 98%;
    height:fit-content;
    border: 1px solid #FF0E41;
    border-radius: 40px;
    font-size: 5vh;
    font-family: 'Lato', sans-serif;
    transition: background-color ease-out 0.3s;
}
.eventContainer:hover{
    background-color: #FF0E41;
    color: #FFFFFF;
}


.eventContainer-header{
    display: flex;
    flex-direction: row;
    margin: 2vh;
}
.showAll{
    margin-left: auto;
}
.eventContainer>.elements{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.eventContainerElement{
    display: flex;
    flex-direction: column;
    width: 50vh;
    margin: 2vh;
    text-align: center;
}
.eventContainerElement>.imgContainer{
    border: 1px solid #707070;
    max-width: 40vh;
    height: 56vh;
    background-color: #FFFFFF;
}


.Footer{
    display: flex;
    flex-direction: row;
    border-top: #707070 1px solid;
    margin-top: 5vh;
    height:  20vh;
    justify-content:space-between;
    align-items: center;
}
.Footer>.menu{
    display: flex;
    flex-direction: row;
    letter-spacing: 0.53px;
    color: #000000;
    font-family: 'Lato', sans-serif;
    font-size: 5.5vh;

}
.Footer> .menu>span{
    margin-left: 5vh;
    margin-right: 5vh;
}
.Footer>.imgContainer>img{
    max-width:20vh ;
    max-height: 20vh;
}
.social{
    display: flex;
    flex-direction: column;
}
.social> .images{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.copyright{
    font-size: 1.7vh;
}



.placeContainer{
    display: grid;
    grid-template-columns: repeat(4,40vh);
    justify-content: space-between;
    padding: 2vh;
    margin: 0;
}
.placeContainerElement>.imgContainer{
    width: 100%;
    height: 100%;
    border: solid #707070 1px;
    background-color: #FFFFFF;
}
.placeContainerElement{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1vh;
    padding: 1vh;
    width: 35vh;
    height: 40vh;
    transition: ease-out 0.3s;
    font-size: 3vh;

}

.placeContainerElement:hover{
    background-color: #7776BC;
}



.calender>.container{
    display: grid;
    grid-template-columns: 30% 60%;

}
.calenderMenu{
    display: flex;
    flex-direction: column;
    margin-left: 3vh;
}
.calenderMenu>.element{
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000012;
    
    width: 80%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh;
    letter-spacing: 1.05px;
    color: #000000;
    text-transform: capitalize;
    font-size: 2.4vh;
}
.calenderEvent{
    display: flex;
    flex-direction: row;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    height: 30vh;
    margin: 3vh;
    width: 110%;
}
.calenderEvent>.imgContainer{
    width: 20vh;
    border:#707070 1px solid;
    margin: 1vh;
    margin-left: 3vh;
    
}
.calenderEvent>.textContainer{
    padding: 5vh;
    font-size: 3vh;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}

.event{
    margin: 2vh;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: column;
}
.event>.container{
    display: flex;
}
.eventch{
    display: flex;
    flex-direction: row;
}
.eventch>div>.imgContainer{
    border: #707070 1px solid;
    width: 40vh;
    height: 60vh;
    resize: none;
}
.eventch>div>.title{
    font-size: 5vh;
}
.eventch>div>.spec{
    font-size: 3vh;
}
.eventch>.container{
    margin-left: 5vw;
}
.event>.about{
    display: flex;

    align-items: center;
    flex-direction: column;
}
.event>.about>h1{
    font-size: 4vh;
}
.allEvent{
    display: grid;
    
}
.columnCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile{
    display: none;
}
.placeInfoAbout{
    margin-left:10vw;
    margin-right:1vw;
    width:60vw;

}
.calendarCanvas{
    height: 100vh;
    width: 100vw;
    font-family: 'Lato', sans-serif;

}
.calendarCanvas>.title{
    font-size: 5vh;
    margin-left: 8vw;
    margin-right: 8vw;
    display: flex
   

}
.calendarCanvas>.title>.month{
    margin-left: auto;
}
.contentCanvas{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px #707070 solid;
    margin: 20px;
    border-radius: 5vh;
}
.contentGrid{
    display: grid;
    grid-template-columns: repeat(7,12vw);

    text-align: center;
    font-size: 3vh;
    margin: 1vh;
}
.contentBox{
    border: #707070 1px solid;

    height: 15vh;
}
.dotBox{
    margin: 1vh;
    display: flex;
}
.dateDot{
    border-radius: 50%;
    width: 2vh;
    height: 2vh;
    margin: 4px
}
.mobile{
    display: none;
}
.calendarLegend{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
}
/*----------------------------------------------------------------------------*/

@media only screen and (max-width: 600px){
/*menu*/
.dropdown{
    display: none;
}
nav{
    opacity: 1;  
    display: flex;
    justify-content: center;
}
nav>span{
    margin-top: 1.5vh;
    font-size: 6vh;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    
}
/*earchbar-main.js*/
.SearchBarMain{
    color:black;
    width: 60%;
    background-color: #FFFFFF;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    align-self: center;
    margin-top: 40vh;

}
.SearchBarMain >input{
    width: 100%;
}

.Home{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image:url("./../images/ankarada2.jpeg");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;

}
.fRouter{display: none;}


/*Mobile Navbar*/
.mobile-navbar{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
    background-color: #FFFFFF;
    transform: translate(-100%);
    transition: all .45s;
}
.mobile-navbar > .header{
    font-size: 4vh;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width:100vw;
    border-bottom: 1px solid #000000;
    margin-bottom: 5vh;
}
.mobile-navbar > .nav{
    font-size: 3vh;
    margin-left: 7vh;
    margin-right: 7vh;
    border-bottom: 0.9px solid #707070;
    padding-bottom: 2vh;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
}
.mobile-navbar>.nav>img{
    margin-left: auto;
    height: 3vh;
    width: 3vh;
}
.mobile-navbar >.list{
    display: flex;
    flex-direction: column;
    margin-left: 10vh;
    margin-right: 10vh;
    margin-bottom: 2vh;
}
.mobile-navbar >.list>.link{
    margin: 1vh;
}
.mobile-navbar.open {
    transform: translate(0);
}

.FilterMenu{
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: row;
    padding-left: 3vh;
    padding-right: 3vh;
}
.filter-calender{
    margin-left:auto ;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF0E41;
    color: #FFFFFF;
    font-size:2vh;
    border-radius: 41px;
    box-shadow: 0px 3px 6px #0000001A;
    width: 12vh;
    height: 5vh;
    
}
.filterMenu-label{
    font-size: 2vh;
    color: #FF0E41;
}
.filter-label{
    font-size: 2vh;
    font-family: 'Lato', sans-serif;
    font-weight:200;
}
.filter{
    position:absolute;
    background-color:#FFFFFF;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    width: 30vw;
}
.filter>span{
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 2vh;
    font-family: 'Lato', sans-serif;
    font-weight:200;
    
}


.eventContainer{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    margin-left: 1%;
    width: 98vw;
    height:fit-content;
    border-bottom: 0.5px solid #FF0E41;
    font-size: 2vh;
    font-family: 'Lato', sans-serif;
    transition: background-color ease-out 0.3s;
}
.eventContainer:hover{
    background-color: #FF0E41;
    color: #FFFFFF;
}


.eventContainer-header{
    display: flex;
    flex-direction: row;
    margin: 2vh;
}
.showAll{
    margin-left: auto;
}
.eventContainer>.elements{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.eventContainerElement{
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 12.5vh;
    margin: 2vh;
    text-align: center;
}
.eventContainerElement>.imgContainer{
    border: 0.1px solid #707070;
    max-width: 20vw;
    height: 10vh;
    background-color: #FFFFFF;
}
.Footer{
    display: flex;
    flex-direction: row;
    border-top: #707070 1px solid;
    margin-top: 5vh;
    width: 100vw;
    height:  20vh;
    justify-content:space-between;
    align-items: center;
}
.Footer>.menu{
    display: flex;
    flex-direction: row;
    letter-spacing: 0.53px;
    color: #000000;
    font-family: 'Lato', sans-serif;
    font-size: 1.5vh;

}
.Footer> .menu>span{
    margin-left: 2vh;
    margin-right: 2vh;
}
.Footer>.imgContainer>img{
    max-width:10vh ;
    max-height: 10vh;
}
.social{
    display: flex;
    flex-direction: column;
}
.social> .images{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.copyright{
    font-size: 1vh;
}

.placeContainer{
    display: grid;
    grid-template-columns: repeat(2,40vw);
    justify-content:space-around;
    padding: 2vh;
    margin: 0;
}
.placeContainerElement>.imgContainer{
    width: 100%;
    height: 25vh;
    border: solid #707070 1px;
    background-color: #FFFFFF;
}
.placeContainerElement{
    display: flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction: column;
    border-radius: 1vh;
    width: 35vw;
    height: 30vh;
    transition: ease-out 0.3s;
    font-size: 2vh;

}
.event{
    margin: 2vh;
    font-family: 'Lato', sans-serif;
    font-size: 2vh;
    display: flex;
    flex-direction: column;
}
.event>.container{
    display: flex;
    margin:2vw;
}
.eventch{
    display: flex;
    flex-direction: column;
}
.eventch>div>.imgContainer{
    border: #707070 1px solid;
    width: 40vh;
    height: 60vh;
    resize: none;

}
.eventch>div>.title{
    font-size: 8vw;
}
.eventch>div>.spec{
    font-size: 5vw;
}
.event>.about{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.event>.about>h1{
    font-size: 7vw;
}
.placeInfoAbout{
    margin: 0;
}
.placeInfo{
    flex-wrap: wrap;
    text-align:left;
    
}
.calendarCanvas{
    height: 100vh;
    width: 100vw;
    font-family: 'Lato', sans-serif;

    
}
.calendarCanvas>.title{
    font-size: 3vh;
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex
   

}
.calendarCanvas>.title>.month{
    margin-left: auto;
}
.contentCanvas{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contentGrid{
    display: grid;
    grid-template-columns: repeat(7,13vw);
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 3vh;
}
.contentBox{
    border: #707070 1px solid;
    height: 15vh;
}

.dotBox{
    margin: 0.5vh;
    display: flex;
    flex-direction: column;
}
.dateDot{
    border-radius: 50%;
    width: 2vh;
    height: 2vh;
    margin: 2px
}
.browser{
    display: none;
}
.calendarLegend{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

}